import React from 'react'

import classes from './BaseCard.module.css'

const BaseCase = (props) => {
    return (
        <div className={`${classes["infoCard-container"]} ${props.className}`} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

export default BaseCase