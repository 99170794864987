import React, { useState, Suspense, useEffect } from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useSelector } from 'react-redux';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { getCompanyUserList, } from '../../Api Methods/Api';
import UsersGrid from '../../components/GridList/UsersGrid';
import FilterSearch from '../../components/PowerSearch/PowerSearch';
import FilterUsersSearch from '../../components/PowerSearch/FilterUsersSearch';

import classes from "./UserManagementPage.module.css"

const UserManagementPageCardGrid = () => {
  // ---------------------------- loader data ---------------------------------
  // const { portalUsers } = useLoaderData();
  // console.log(portalUsers);

  // useEffect(() => {
  //   if (portalUsers.isError) {
  //     return portalUsers.message;
  //   }
  // }, [portalUsers])


  // useEffect(() => {
  //   setFilteredResults(portalUsers);
  // }, [portalUsers])

  //---------------------------------------------------------------------------

  const [filteredResults, setFilteredResults] = useState([]);
  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);

  const apiData = {
    token,
    client
  }

  const [searchQuery, setSearchQuery] = useState("");

  const [isloading, setIsLoading] = useState(false)

  const handleFilterLogic = async (data, searchQuery, selectedCategories) => {

    const users = await data;

    // Filter data based on search query and category
    const filteredResults = users.filter(user => {

      // Check if the result matches the search query
      const matchesSearchQuery = user.fullName && user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) || user.email.toLowerCase().includes(searchQuery.toLowerCase());

      // // Check if the result matches the selected category
      // let matchesCategory = true;
      // if (selectedCategories.length > 0) {
      //     //----
      //     if (selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.favorited;
      //     }
      //     if (selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = !result.favorited;
      //     }
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.favorited || !result.favorited;
      //     }
      //     //----
      //     if (selectedCategories.includes('Notifications On')) {
      //         matchesCategory = isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Notifications On') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = isPinNotificationsActive == true || isPinNotificationsActive == false;
      //     }
      //     //----
      //     if (selectedCategories.includes('Online')) {
      //         matchesCategory = result.online === "YES";
      //     }
      //     if (selectedCategories.includes('Offline')) {
      //         matchesCategory = result.online === "NO";
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Offline')) {
      //         matchesCategory = result.online === "YES" || result.online === "NO";
      //     }
      //     //--
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.favorited || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = !result.favorited || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.favorited || isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = !result.favorited || isPinNotificationsActive == false;
      //     }
      //     //--
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.online === "YES" || result.favorited;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.online === "NO" || result.favorited;
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.online === "YES" || !result.favorited;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.online === "NO" || !result.favorited;
      //     }
      //     //--
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.online === "YES" || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.online === "NO" || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.online === "YES" || isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.online === "NO" || isPinNotificationsActive == false;
      //     }



      // }

      // if (selectedCategories.length > 0) {
      //     return matchesSearchQuery && matchesCategory;
      // }
      return matchesSearchQuery
    });

    setFilteredResults(filteredResults);
  };

  return (
    <div className={classes['userManagementPage_container']}>
      <FilterUsersSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Users..."} setIsLoading={setIsLoading} setFilteredResults={setFilteredResults} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />
      <Suspense fallback={
        <div className={classes["loadingSpinner-container"]}>
          {/* <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} /> */}
        </div>
      }>
        <Await resolve={filteredResults}>
          {(loadedFilteredResults) =>
            // <GridList2 data={loadedSearchResults} />
            <div style={{ display: "flex", width: "100%" }}>
              <h3>{isloading && "loading"}</h3>

              {searchQuery !== "" && filteredResults.length != 0 && <UsersGrid data={loadedFilteredResults} apiData={apiData} />}
            </div >
          }

        </Await>
      </Suspense>

    </div>
  )
}

export default UserManagementPageCardGrid

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');

  const apiData = {
    token,
    client
  }

  return defer({
    // portalUsers: getCompanyUserList(apiData, companyName),
  })
}
