import React, { useState } from 'react'
import { setTag } from '../../../Api Methods/Api';
import Input from '../../CustomDropDown/Input/Input';
import Button1 from '../../UI/Button/Button1';
import classes2 from "../../forms/InviteUserForm.module.css"
import classes from "./SetTag.module.css"

const SetTag = ({ placeholder, DeviceUUID, HandleCloseModal, dispatch, setUpdateTag, update, setUpdate, siteDevices, setSiteDevices }) => {

    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');

    const [tag, SetTag] = useState('');

    const handleInputChange = (event) => {
        SetTag(event.target.value);
    };

    const handleCancel = () => {
        SetTag('');
        HandleCloseModal();
    };

    const handleSaveTag = async () => {
        const apiData = {
            token,
            client,
        }

        const response = await setTag(apiData, DeviceUUID, tag, dispatch);
        if (response) {
            setUpdateTag && setUpdateTag(tag);
            HandleCloseModal();
            setSiteDevices((prevSiteDevices) =>
                prevSiteDevices.map((device) =>
                    device.uuid === DeviceUUID ? { ...device, tag: tag } : device
                )
            );
        }
    }

    return (
        <form onSubmit={handleSaveTag} className={classes2["container"]} method="dialog" >
            <ul className={classes2["inputs-list"]}>
                <div className={classes2.label}>Set Tag</div>
                <li className={classes2["input-container"]}>

                    <Input
                        type="text"
                        value={tag}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                    />
                </li>
                <div className={classes.form}>
                    <Button1 type="button" onClick={handleCancel}> Cancel </Button1>
                    <Button1 type="submit"> Save  </Button1>
                </div>
            </ul>
        </form>
    )
}

export default SetTag;