import React, { Suspense, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserCardContentLayout from '../../components/UI/DataLayouts/UserCardContentLayout';
import GridPaginationControls from '../../components/Table/Table/GridPaginationControls';
import Card from './Card';
import classes from './GroupUsers.module.css'
import DataManager from '../../hooks/DataManager';
import { getGroupUsers } from '../../Api Methods/Api';
import { Await, Outlet } from 'react-router-dom';
import GroupUserCardContentLayout from './GroupUserCardContentLayout';

const GroupUsers = () => {

    const dataManager = new DataManager();
    const groupID = dataManager.getItem('groupClickedID');

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
      token,
      client
    }
    const dispatch = useDispatch();

    const [groupUsers, setGroupUsers] = useState();
    const [update, setUpdate] = useState(0);

    // const [pageData, setPageData] = useState(data.slice(0, 50))
    // const [pageSize, setPageSize] = useState(50)
    // const [pageIndex, setPageIndex] = useState(1)
    // const [pageCount, setPageCount] = useState(Math.ceil(data.length / pageSize))

    useEffect(() => {
        console.log("updated: ", groupUsers)
    }, [groupUsers])
    

    useEffect(() => {
        // const GetGroupUsers = async () => {
        //     const response = await getGroupUsers(apiData, groupID, dispatch);
        //     setGroupUsers(response.data[0]);
        // }

        // GetGroupUsers();

        fetchUsers()
        .then((response) => {
            setGroupUsers(response.data);  // Set state should trigger re-render
        })
        .catch((error) => {
            console.error('Error fetching users:', error);
        });
    }, [])

    async function fetchUsers() {
        return await getGroupUsers(apiData, groupID, dispatch)
    }

    return (
        <div className={classes["grid-container"]}>
            <Await resolve={groupUsers}>

            
         
            <>
                <grid className={classes["grid-ul"]} >
                
                {Array.isArray(groupUsers) && groupUsers.map((user, index) => (
                    <li className={classes["grid-li"]} key={index}>
                        {/* Key passed to act as index for each card, to make it so when a card is changed it replays the fade from top animation :: key={index + pageIndex * pageSize} */}
                        <Card className={classes.card} index={index} color={user}>
                            <GroupUserCardContentLayout cardData={user} apiData={apiData} />
                        </Card>
                    </li>
                ))}
                </grid>
            </>
        
        

        {/* <GridPaginationControls pageIndex={pageIndex} nextPage={HandleNextPage} previousPage={HandlePreviousPage} setPageIndex={HandleSetPageIndex} pageCount={pageCount} setPageSize={HandlePageSize} PageSize={pageSize} PaginationOptions={[50, 75, 100]} /> */}
        </Await>
    </div >
    )
}

export default GroupUsers;