import React from 'react'
import classes from './TotalEvent.module.css';
import BaseCase from '../../Card/BaseCase';
import { ReactComponent as Arrow } from '../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg';


const TotalEvents = ({totalEvents}, props) => {

    const image = '__Drop Down menu';
    const image2 = '__Drop Down menu';
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/";
    const UpArrow = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    const DownArrow = `${process.env.PUBLIC_URL}${staticPath}${image2}.svg`

    return (
        <BaseCase className={classes["infoCard-container"]}>
            
            <h2 style={{marginRight: "10px"}}>Total Events: </h2>
            <h2>{totalEvents || String.fromCharCode(160) + "0"}</h2>
   
            {/* <div className={classes["svg-container"]} style={{marginRight: "5px"}}> */}
           {/* {totalEvents < 50 ? <Arrow className={classes["svg"]} stroke='rgb(25, 192, 25)' width='40' height='40' /> :
           <Arrow stroke='red' width='40' height='40' /> 
           } */}
            {/* </div>  */}
            {/* <h2>{totalEvents > 50 ? "24" : "16"}%</h2> */}
        </BaseCase>
    )
}

export default TotalEvents;