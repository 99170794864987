import React, { Children, useEffect, useRef, useState } from 'react'

import classes from "./UsersCardContentLayout.module.css";
import StatusIndicatorCard from '../Cards/StatusIndicatorCard/StatusIndicatorCard';

import FavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - On.svg";
import UnFavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - Off.svg";

import ActiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";

import installerActive from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer-on.png";
import installerInactive from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer off.png";

import SettingsCog from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"

import arrowIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";

import { Link, useNavigate } from "react-router-dom";
import CompanyIndicatorCard from '../Cards/StatusIndicatorCard/CompanyIndicatorCard';
import OutputPinCircle from './OutputPinCircle';
import InputPinCircle from './InputPinCircle';
import CustomLink from '../../CustomLink/CustomLink';
import Pill_Widget from '../Widgets/Pill_Widget';
import { authActions } from '../../../store/auth-Slice';
import { useDispatch } from 'react-redux';
import DropdownList from '../Cards/DropdownList';
import useClickOutside from '../../hooks/useClickOutside';
import { enableUserNotifications, switchInstallerMode } from '../../../Api Methods/Api';



const UserCardContentLayout = ({ cardData, apiData }) => {

    const [userData, setUserData] = useState(cardData);
    useEffect(() => {
        setUserData(cardData);
    }, [cardData])


    const { created, email, fullName, id, lastLoggedIn, notifications, pin, portalAccess, portalPassword, portalRole, salt, timezone, token, twoFactor, verified, verifiedOn } = userData;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const HandleNavigateToUsersDevices = (UserID, fullName) => {
        sessionStorage.setItem('ClickedUserID', UserID);
        sessionStorage.setItem('ClickedUserFullName', fullName);

        dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

        navigate("/Dashboard/Users/UserSites");
    }


    // Dropdown Options
    let items = [
        {
            image:
                notifications == "yes"
                    ? InactiveNotificationBellIcon
                    : ActiveNotificationBellIcon,
            title:
                notifications == "yes"
                    ? "Disable Notifications"
                    : "Enable Notifications",
        },
        {
            image: 
                portalAccess != "" && portalAccess != null
                    ? installerInactive
                    : installerActive,
            title: 
                portalAccess != "" && portalAccess != null
                    ? "Disable Installer Mode"
                    : "Enable Installer Mode",

        }

    ];

    // -- Controls the settingCog wheel -------------------------------------- 
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
        setOpenDropdownRowId(null);
    });

    // -- Controls the settingCog wheel -------------------------------------- 

    const handleSettingsClick = (rowId) => {

        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
            setOpenDropdownRowId(null);
        } else {
            // Otherwise, open the clicked dropdown
            setOpenDropdownRowId(rowId);
        }
    };
    // -----------------------------------------------------------------------

    const [isNotificationsEnabled, setIsNotificationsEnabled] = useState()


    // ---------- carrys out clicked action in dropdown --------------------------------------------------------------------------------------------------------------
    const UserActionHandler = (userAction, userId, notifications) => {
        switch (userAction) {

            case "Enable Installer Mode":
                const enableInstallerMode = async (apiData, userID) => {
                    const response = await switchInstallerMode(apiData, userID, "INSTALLER")

                    if (true) {
                        let data = await cardData
                        data.portalAccess = "INSTALLER"
                        setUserData(data)
                        setOpenDropdownRowId(null);
                    }
                }

                enableInstallerMode(apiData, userId)
                break;
            case "Disable Installer Mode":
                const disableInstallerMode = async (apiData, userID) => {
                    const response = await switchInstallerMode(apiData, userID, "")

                    if (true) {
                        let data = await cardData
                        data.portalAccess = ""
                        setUserData(data)
                        setOpenDropdownRowId(null);
                    }
                }

                disableInstallerMode(apiData, userId)
                break;
            case "Disable Notifications":
            case "Enable Notifications":
                const EnableUserNotifications = async (apiData, userId, notifications) => {

                    const enableNotification = notifications == "yes" ? "no" : "yes"
                    const responseData = await enableUserNotifications(apiData, userId, enableNotification, dispatch)

                    if (true) {
                        let data = await cardData;
                        data.notifications = enableNotification;
                        setUserData(data);
                        setOpenDropdownRowId(null);
                    }
                }
                EnableUserNotifications(apiData, userId, notifications);
                break;
                break;
            default:
                console.alert('Invalid option:', userAction);
        }
    }
    const ifSettingsCogIsClicked = openDropdownRowId === id
    return (
        <div className={classes.GroupCardContentLayout}>

            <div className={classes["top-wrapper"]} >
                <div className={classes["siteDetails-layout"]} onClick={() => HandleNavigateToUsersDevices(id, fullName)}>
                    <h3  >{fullName}</h3>
                    <h3 style={{ fontWeight: "400" }} className={classes.tagName}>{email}</h3>
                </div>
                <div className={classes["icons-container"]}>
                    <DropdownList
                        item={id}
                        icon={SettingsCog}
                        onClick={handleSettingsClick}
                        ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                        buttonRef={buttonRef}
                        ref={dropdownRef}
                        title={"Actions"}
                        className={classes.container}
                        onUserActionClick={(userAction) =>
                            UserActionHandler(
                                userAction,
                                id,
                                notifications
                            )
                        }
                        listItems={items}
                    />
                </div>
            </div>

        </div>
    )
}

export default UserCardContentLayout;

