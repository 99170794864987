import React, { Suspense, useMemo, useState, useEffect } from 'react'
import { getGroupSites } from '../../Api Methods/Api';
import { Await, defer, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classes from './GroupSites.module.css'
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import DataManager from '../../hooks/DataManager';
import Button1 from '../../components/UI/Button/Button1';
import { alignProperty } from '@mui/material/styles/cssUtils';

const GroupSites = () => {

    const [groupSites, setGroupSites] = useState()
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const [searchQuery, setSearchQuery] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [groupMode, setGroupMode] = useState(false);
    const dataManager = new DataManager();
    const groupName = dataManager.getItem('groupClicked');
    const navigate = useNavigate();
    const apiData = {
        token,
        client
    }

    const location = useLocation();
    console.log('Current path:', location.pathname);
    const isGroupUsersPage = location.pathname.includes('GroupUsers'); // Check if on GroupUsers page

    let data;

    useEffect(() => {
        const HandleGetGroupSites = async () => {
          data = await getGroupSites(apiData, groupName)
          setGroupSites(data)
          console.log(data);
        }
    
        HandleGetGroupSites()
      }, [])

    const handleFilterLogic = async (data, searchQuery) => {
        const Data = await data;
        console.log(searchQuery)
        const filteredResults = Data.filter(result => {
            const query = searchQuery.toLowerCase();
            const nameMatch = result.name.toLowerCase().includes(query);
            const keyMatch = result.key.toLowerCase().includes(query);
            const tagMatch = result.tag.toLowerCase().includes(query);
            return nameMatch || keyMatch || tagMatch;
        });

        setGroupSites(filteredResults)        
    };

    const addSites =() => {
        dataManager.setItem('isGroupMode', true);
        navigate('/Dashboard/');
    }

    const editSites = () => {
        dataManager.setItem('isGroupMode', !groupMode);
        setGroupMode(!groupMode);
    }

    const viewUsers = () => {
        navigate('/Dashboard/Groups/GroupSites/GroupUsers/');
    }

    return (
    <div className={classes['activtyPage_container']}>
        {!isGroupUsersPage && (
            <>
        {groupSites && <FilterUsersDeviceSearch Devices={groupSites} searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Sites..."} setIsLoading={setIsLoading} setFilteredResults={setGroupSites} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />}
        
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: "0px", marginTop:"0px", gap:"10px"}}>
            <div style={{ display: "flex", gap: "10px" }}>
                <Button1 style={{height: "50px", width: "110px", fontSize: "16px"}} onClick={addSites}>Add sites</Button1>
                <Button1 style={{height: "50px", width: "110px", fontSize: "16px"}} onClick={editSites}>Edit sites</Button1>
            </div>
            <Button1 style={{height: "50px", width: "110px", fontSize: "16px"}} onClick={viewUsers}>View Users</Button1>
        </div>

        <Suspense fallback={
        <div className={classes["loadingSpinner-container"]}>
            <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
        </div>
        }>
        <Await resolve={groupSites}>
            {(loadedUserDevices) =>
            // <GridList2 data={loadedSearchResults} />
            <div style={{ display: "flex", width: "100%" }}>
                { groupSites ? (<DevicesGrid data={loadedUserDevices} pageFetchedIndex={1} isUsersSites={true} groupMode={groupMode}/>
                ) : (
                <p>Loading sites...</p>        
                )}
            </div >
            }
             
             
        </Await>
        </Suspense>
        </>
        )}
        <Outlet />

        
    </div>
    )
}

export default GroupSites

export async function loader(){
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const apiData = {
        token,
        client
      }

    return defer ({
        groupSites: getGroupSites(apiData, "EyeSpyFX")
    })
}