import React, { Children, useEffect, useState, useRef } from 'react'
import classes from './Groups.module.css'
import UserIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/home.png';
import CustomLink from '../../components/CustomLink/CustomLink';
import arrowIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import SettingsCog from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/gear.png'
import AddUser from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/addUser.png'
import TransferGroup from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/transfer.png'
import DeleteGroup from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/deleteGroup.png'
import RenameGroupImg from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg'
import DropdownList from '../../components/UI/Cards/DropdownList';
import useClickOutside from '../../components/hooks/useClickOutside';
import Modal from '../../components/Modal/Modal';
import InputFormCreateGroup from '../../components/forms/InputFormCreateGroup';
import Button1 from '../../components/UI/Button/Button1';
import DropDownForm from '../../components/forms/DropdownCreateGroup';
import DataManager from '../../hooks/DataManager';
import { addUserToGroup, deleteGroup, renameGroup } from '../../Api Methods/Api';
import { useDispatch, useSelector } from 'react-redux';
import ParentIndicator from './ParentIndicator'
import { ApiEndpoints } from '../../store/type/API-Endpoints';

const GroupCard = ({cardData, handleGroupClick, groups, setUpdate, update}) => {

    const groupName = cardData.name;
    const siteCount = cardData.deviceCount;
    const groupID = cardData.id;
    console.log(groupID)

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
    const [modalComponent, setModalComponent] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");
    const [name, setName] = useState();
    const [selectedGroup, setSelectedGroup] = useState();
    const [title, setTitle] = useState('');
    const dataManager = new DataManager();

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
      token,
      client
    }
    const dispatch = useDispatch();

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked); // Update the state based on checkbox value
    };

    const HandleShowModal = () => {
      ModalDialog.current.open();
    };
  
    const HandleCloseModal = () => {
      ModalDialog.current.close();
    };

    const ModalDialog = useRef();

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
      setOpenDropdownRowId(null);
    });

    const handleSettingsClick = (rowId) => {
      // If the clicked dropdown is already open, close it
      if (openDropdownRowId === rowId) {
          setOpenDropdownRowId(null);
      } else {
          // Otherwise, open the clicked dropdown
          setOpenDropdownRowId(rowId);
      }
    };

    let items = [
        {
            image: AddUser,
            title: "Add user",
        },
        {
            image: TransferGroup,
            title: "Transfer group",
        },
        {
            image: DeleteGroup,
            title: "Delete group"
        }, 
        {
            image: RenameGroupImg, 
            title: "Rename group"
        },
    ];

    const showAddUserModal = () => {
      setTitle("Add user")
      setModalComponent(
        <div>
          <div>
            <InputFormCreateGroup label={"Enter users email address"} setName={setName}></InputFormCreateGroup>
          </div>     
          <br></br>
          <div>
          <label>
            <input 
              type="checkbox" 
              checked={isChecked} 
              onChange={handleCheckboxChange}
            />
            Administrator
          </label>
          </div>
          <br></br>
          <Button1 onClick={addUser}>Add</Button1>
        </div>
      )  
      HandleShowModal();
    }

    const addUser = async () => {
      const email = dataManager.getItem('userToInvite');
      if (email != ''){

        let body = {
          groupID: groupID, 
          email: email, 
          role: "TechSupport", 
          owner: isChecked ? 0 : 1,
        }

        const response = await addUserToGroup(apiData, body, dispatch)
        if (response){
          dataManager.setItem('userToInvite', "");
          HandleCloseModal();
          console.log(response);
        } else {
          //error
        }
      }
    }

    const showTransferGroupModal = () => {
      setTitle("Transfer group")
      const groupNames = groups.map(group => group.name);
      setModalComponent(
        <div>
          <div>
          <DropDownForm label={"Please select the new owner below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
        </div>
        <br></br>
          <Button1 onClick={transferGroup}>Transfer</Button1>
        </div>
      )  
      HandleShowModal();
    }

    const transferGroup = () => {

      console.log("transferGroup")
    }

    const showDeleteGroupModal = (groupID) => {
      setTitle("Delete group")
      setModalComponent(
        <div>
          <div style={{textAlign: "center"}}>
            <h2>Are you sure you want to delete this group?</h2>
            <h3 style={{textAlign:"center"}}>This action cannot be undone!</h3>
          </div>
          <br></br>
          <div style={{alignSelf:"center"}}>
            <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
            <Button1 onClick={() => deleteGroupAPI(groupID)}>Yes, I'm sure</Button1>
          </div>
        </div>
      ) 

      HandleShowModal();
    }

    const deleteGroupAPI = async (groupID) => {
      console.log("deleteGroup");
      const response = await deleteGroup(apiData, groupID, dispatch)
      if (response){
        //refresh
        setUpdate(update+1);
        HandleCloseModal();
      } else {
        //error
      }
    }

    const showRenameGroupModal = (groupID) => {
      setTitle("Rename group")
      setModalComponent(
        <div>
        <div>
          <InputFormCreateGroup label={"Enter new group name"} setName={setName}></InputFormCreateGroup>
        </div>     
        <br></br>
        <Button1 onClick={() => RenameGroup(groupID)}>Rename</Button1>
      </div>
      ) 

      HandleShowModal();
    }

    const RenameGroup = async (groupID) => {
      console.log("renameGroup");
      const newName = dataManager.getItem('groupName');
      const oldName = groupName;
      let body = {
        groupID: groupID, 
        oldName: oldName, 
        newName: newName
      };

      const response = await renameGroup(apiData, body, dispatch)
      if (response){
        setUpdate(update+1);
        HandleCloseModal();
      } else {
        //error
      }
      
    }

    const UserActionHandler = (userAction, groupID) => {
     switch (userAction){
      case 'Add user': 
        showAddUserModal();
      break;
      case 'Transfer group': 
        showTransferGroupModal();
      break;
      case 'Delete group':
        dataManager.setItem('groupClickedID', groupID);
        showDeleteGroupModal(groupID);
      break;
      case 'Rename group':
        dataManager.setItem('groupClickedID', groupID);
        showRenameGroupModal(groupID);
      break;
     }
    }

    const ifSettingsCogIsClicked = openDropdownRowId === groupID

    return (
      <>
      <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
          {modalComponent}
      </Modal>

        <div className={classes.GroupCardContentLayout}>
          <div className={classes.userCount}>
            <h3>
              <img className={classes.Icon} src={UserIcon} style={{ marginRight: "5px", marginBottom: "-3px"}} />
              {siteCount}
            </h3>  
          </div> 
          <div className={classes["top-wrapper"]}>
            <div className={classes.settingsIcon}>
              <DropdownList
                item={groupID}
                icon={SettingsCog}
                onClick={handleSettingsClick}
                ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                buttonRef={buttonRef}
                ref={dropdownRef}
                title={"Actions"}
                className={classes.container}
                onUserActionClick={(userAction) => UserActionHandler(userAction, groupID)}
                listItems={items}
              />
            </div>
            <div className={classes["siteDetails-layout"]}>
              <h3>
                {groupName}
              </h3>
            </div>
          </div>
          <div className={classes.indicatorsCardsContainer} style={{marginTop:"10px"}}>        
              <ParentIndicator companyName={"EyeSpyFX"}/>
          </div>

          <CustomLink className={classes.Link} to={`/Dashboard/Groups/GroupSites`} >
                  <div className={classes.arrowButton}  onClick={() => handleGroupClick(groupName, groupID)}>
                    <img className={classes.Icon} src={arrowIcon}/> 
                  </div>
                </CustomLink>
        </div>

        
      </>
    );
}

export default GroupCard