import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigation, useLocation } from "react-router-dom";

import { Outlet } from "react-router-dom";

import Header from "../../../components/UI/Header/Header";

import SideNav from '../../../components/UI/side-navBar/SideNav/SideNav';

import classes from "./SubRootLayout.module.css";

import Breadcrumbs from "../../../components/Breadcrumb/Breadcrumbs";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import useSocketManager from "../../../Socket/useSocketManager";
import { LCPThresholds } from "web-vitals";
import { Oval } from "react-loading-icons";
import { serviceActions } from "../../../store/service-Slice";

const SubRootLayout = (props) => {
    // --------------- Handle Toggle SideNav Logic ------------------------------
    const dispatch = useDispatch();
    const ShowSideNav = useSelector(state => state.ui.showSideNav);
    const [isMoblie, setIsMoblie] = useState(false)
    const [cssClass, setCssClass] = useState({ display: 'none' });

    function findClosestToEnd(array, target) {
        let closestIndex = -1;
        let closestDistance = Infinity;

        for (let i = 0; i < array.length; i++) {
            const currentIndex = target.lastIndexOf(array[i]);
            if (currentIndex !== -1) {
                const distance = target.length - 1 - currentIndex;
                if (distance < closestDistance) {
                    closestIndex = i;
                    closestDistance = distance;
                }
            }
        }

        if (closestIndex !== -1) {
            return array[closestIndex];
        } else {
            // If none of the strings are found in the other array, return null or handle it as needed
            return null;
        }
    }
    const pages = ["Dashboard", "Users", "UsersDevices", "Activity", "SiteView"];
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const currentpage = findClosestToEnd(pages, pathArray);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1050px)'); // Define your media query here

        // Function to handle changes in the media query
        const handleIsMobile = () => {
            if (window.innerWidth <= 1050) {
                setIsMoblie(true)

            } else {
                setIsMoblie(false)
                dispatch(uiActions.ShowSideNav(false));
            }
        };

        // Initial check for if screen has been resized
        handleIsMobile(mediaQuery);

        // Add event listener for changes in the media query
        mediaQuery.addListener(handleIsMobile);

        // Clean up by removing event listener when component unmounts
        return () => {
            mediaQuery.removeListener(handleIsMobile);
        };
    }, []);

    useEffect(() => {
        if (!isMoblie || ShowSideNav) {
            // setCssClass({ width: "105px" });
            setCssClass({ width: "205px" });
        } else if (isMoblie || ShowSideNav) {
            setCssClass({ width: "0px" });
        }
    }, [isMoblie, ShowSideNav]);

    //------------------------------------------------------------------------------

    //---------------------------- On login Basic socket connection (auto connection: true) --------------------------------
    // let isSocketConnected;
    const isSocketConnected = useSocketManager()
    dispatch(serviceActions.updateSocketConnectionStatus(isSocketConnected));
    //------------------------------------------------------------------------------

    const navigation = useNavigation();
    const isLoading = navigation.state === "loading";

    return (
        <div className={classes['screen']}>
            <div className={classes['page-wrapper']} >
                <div className={classes['header-wrapper']}>
                    <Header isSocketConnected={isSocketConnected} findClosestToEnd={findClosestToEnd} />
                </div>
                <div className={classes['body-wrapper']}>
                    <div className={classes['nav-wapper']}>
                        <SideNav cssClass={cssClass} />
                    </div>

                    <div className={classes['page-Wrapper']} style={{ gap: currentpage == "SiteView" && "15px" }}>
                        {/* {isLoading &&
                            <div className={classes["loading-overlay"]}>
                                <div className={classes["loadingSpinner-container"]}>
                                    <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                                </div>
                            </div>
                        } */}
                        <div className={classes['breadcrumb-Wrapper']}>
                            <Breadcrumbs />
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubRootLayout;