import React, { useState, useMemo, useRef, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeUsersRole, removeUserFromDeviceManagement, getDeviceEvents } from "../../../store/service-actions";
import { getDeviceUsers } from "../../../store/service-actions";
import classes from './SiteView.module.css';
import AppView from "../../UI/Pin/AppView/AppView";
import ContorlsBar from "../../UI/ControlsBar/ControlsBarV2/ControlsBarV2";
import Logs from "../../sub-components/Logs/Logs";
import { defer, useLoaderData } from "react-router-dom";
import { serviceActions } from "../../../store/service-Slice";
import GroupsGrid from "../../GridList/GroupsGrid";
import { getDeviceEventLogsRanged, getSingleDeviceData, getSingleDeviceUsers, changeRole, removeUser, inviteUser, getInputRules, getSchedules, getCamectsAPI, getHolidaysModesAPI, getSigFoxSensorsAPI } from "../../../Api Methods/Api";
import Modal from "../../Modal/Modal";
import HandleResponseData from "../../../hooks/HandleResponseData";
import SingleDeviceUsersTableLayout from "../../UI/Table/SingleDeviceUsersTableLayout";
import { Await, useNavigate } from 'react-router'
import { Oval } from "react-loading-icons";
import Automations from "../../UI/Cards/Automations/Automations";
import VirtualSources from "../../UI/Cards/Automations/VirutalSources/VirtualSources";
import { useSocketHook } from "../../../Socket/useSocketHook";
import parsePinStates from "../../../Socket/parsePinStates";
import { authActions } from "../../../store/auth-Slice";

const SiteView = () => {
    // ---------------------------- loader data ---------------------------------
    const { SiteData, SiteEventLogs, SiteDeviceUserList } = useLoaderData();

    useEffect(() => {
        if (SiteData.isError) {
            return SiteData.message;
        }

        // if (SiteEventLogs.isError) {
        //     return SiteEventLogs.message;
        // }

        if (SiteDeviceUserList.isError) {
            //         return SiteDeviceUserList.message;
            //     }
        }

    }, [SiteData, SiteEventLogs, SiteDeviceUserList])

    //update deviceUUID to stay sync with session storage.
    const dispatch = useDispatch();
    const sesstionDeviceUUID = sessionStorage.getItem("DeviceUUID")
    dispatch(authActions.updateDeviceUUID(sesstionDeviceUUID));

    const [parsedSiteData, setParsedSiteData] = useState(SiteData);

    const [hasDeviceChanged, setHasDeviceChanged] = useState();

    useEffect(() => {
        const HandleDeviceData = async () => {
            if (hasDeviceChanged) {
                const SingleDeviceData = await getSingleDeviceData(apiData, DeviceUUID);
                console.log(SingleDeviceData)
                setParsedSiteData(SingleDeviceData);
                //setHasDeviceChanged(false);
            }
        }
        HandleDeviceData();
    }, [hasDeviceChanged]);

    const siteData = HandleResponseData(parsedSiteData);
    const firmware = siteData[1].firmware
    console.log(firmware)

    //Get array of pinNames
    const OutPutsPinNames = siteData[0].OutputPins.map((Output) => {
        const { pinName, Pin, pinStatusOn, pinStatusOff, type, ...rest } = Output;
        return Output
    })

    // check if device has no inputs if not then skip
    let InputsPinNames;
    if (siteData[1].numInput != 0) {
        InputsPinNames = siteData[0].InputPins.map((Input) => {
            const { pinName, Pin, pinStatusOn, pinStatusOff, ...rest } = Input;
            return { Pin, pinName, pinStatusOn, pinStatusOff }
        })
    }

    //---------------------------------------------------------------------------------------------------------------------

    console.count("SiteView RUNNING")
    const client = useSelector(state => state.auth.client);
    // const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);
    const userRole = useSelector(state => state.auth.role);
    const companyName = useSelector(state => state.auth.companyName);


    const deviceId = siteData[1].id;
    const deviceTag = siteData[1].tag;
    const uipioid = siteData[1].key;
    const userEmail = useSelector(state => state.auth.email);


    let DeviceName = siteData[1].deviceName != "" ? siteData[1].deviceName : siteData[1].tag
    //if untagged & unnamed set as key 
    if (DeviceName == "" || DeviceName == null) {
        DeviceName = uipioid
    }

    sessionStorage.setItem('DeviceID', deviceId);
    sessionStorage.setItem('DeviceName', DeviceName);
    const [deviceData, setDeviceData] = useState(siteData[0]);
    console.log(deviceData);
    const [deviceEventsList, setDeviceEventsList] = useState(SiteEventLogs);

    const [deviceUserList, setDeviceUsersList] = useState(SiteDeviceUserList);

    const apiData = {
        token,
        client,
    }

    // passed from header to trigger get single data fetch since camectintegrationType is outdated
    const CamectIntegationType = useSelector(state => state.service.CamectIntegationType);
    console.log(CamectIntegationType);

    const [activeTable, setActiveTable] = useState("Events");

    console.log(activeTable);
    const tableNameHandler = (tableId) => {
        setActiveTable(tableId);
    }

    console.log(activeTable == "Events");
    //------------------------------- responsible for updating the SiteView Page data every 1 mintue ------------------------------------------------------
    // useEffect(() => {

    //     const HandleDeviceData = async () => {
    //         const data = await getSingleDeviceData(apiData, DeviceUUID);
//         setParsedSiteData(data)
    //     }

    //     // const HandleDeviceUserList = async () => {
    //     //     const data = await getSingleDeviceUsers(apiData, DeviceUUID, dispatch)
    //     //     setDeviceUsersList(data.data)
    //     // }

    //     const HandleDeviceEventsList = async () => {
    //         console.log("here");
    //         const data = await getDeviceEventLogs(apiData, DeviceUUID, dispatch)
    //         setDeviceEventsList(data)
    //     }

    //     // Set up a timer to fetch data every 1 minute
    //     const timer1 = setInterval(() => {
    //         console.log("here");
    //         // HandleDeviceUserList();

    //         if (activeTable == "Events") {
    //             HandleDeviceEventsList();
    //         }

    //     }, 10000);


    //     if (CamectIntegationType != null) {
    //         HandleDeviceData();
    //         dispatch(serviceActions.setCamectIntegationType(null));
    //     }

    //     return () => clearInterval(timer1);
    // }, [CamectIntegationType]);


    //---------------------------------------------------------------------------------------------------------------------

    const HandleDeviceUserList = async () => {
        const data = await getSingleDeviceUsers(apiData, DeviceUUID, dispatch)
        console.log(data);
        setDeviceUsersList(data)
    }

    //-------------------- Responsible for passing device info to service slice for the header component to have acces to -----------------------------------------------------------
    useEffect(() => {
        dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
    }, [siteData])
    //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //-----  Adding DeviceId && Device UUID to each user object to supply needed fetch requirements
    const DeviceId = siteData[1].id
    const DeviceUUID = siteData[1].uuid;
    //--------------------------------------------

    const DeviceEvents = useSelector(state => state.service.singleDeviceEvents);
    // const dfCtx = useContext(DataFetchContext);
    // const [isInitialRender, setInitialRender] = useState("Logs");

    const [refreshTable, setRefreshTable] = useState(false);


    const HandleRefreshTable = () => {
        setRefreshTable(true);
    }

    // ----------------- Refresh Table Data Logic ------------------------------------------
    // useEffect(() => {

    //     if (refreshTable === true) {
    //         console.log("here");


    //     }
    //     const timer = setTimeout(() => {
    //         // prevents the user from trigger the refresh button every 10 seconds.
    //         setRefreshTable(false);
    //     }, 10000)


    //     return () => clearTimeout();
    // }, [refreshTable])

    //------------------------------------------------------------------------------------
    //------------------------------- Modal Logic  ----------------------------------------------
    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()


    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        setModalComponent(<></>)

    }

    // useEffect(() => {
    //     ModalDialog.current.closeOnEscape();
    //     console.log("here");
    // }, [])

    //---------------------------------------------------------------------------------------------

    const iglooLocks = siteData[0].singleDeviceSettingData.iglooLocks
    console.log(siteData)
    console.log(iglooLocks)

    const renderTable = (tableId) => {
        switch (tableId) {
            case 'Events':
                return (
                    // <Suspense fallback={
                    //     <div className={classes["loadingSpinner-container"]}>
                    //         <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                    //             <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                    //         </div>
                    //     </div>
                    // }>
                    //     <Await resolve={deviceEventsList}>
                    //         {(loadedDeviceEventsList) =>
                    //             <Logs EventsData={loadedDeviceEventsList} />
                    //         }
                    //     </Await>
                    // </Suspense>
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={SiteEventLogs}>
                            {(loadedDeviceEventsList) =>
                                <Logs SiteEventLogs={loadedDeviceEventsList} apiData={apiData} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Users':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={deviceUserList}>
                            {(loadedDeviceUserList) =>
                                <SingleDeviceUsersTableLayout SiteDeviceUserList={loadedDeviceUserList} setModalTitle={setModalTitle} setModalComponent={setModalComponent} deviceTag={deviceTag} apiData={apiData} deviceId={deviceId} userID={userID} userRole={userRole} userEmail={userEmail} DeviceName={DeviceName} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} DeviceUUID={DeviceUUID} companyName={companyName} HandleDeviceUserList={HandleDeviceUserList} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Automations':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={siteData[0]}>
                            {(loadedSiteData) =>
                                <Automations OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} deviceData={loadedSiteData} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Groups':
                return <GroupsGrid />
            case 'Sources':
                return (

                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>

                        <Await resolve={iglooLocks}>
                            {(LoadedIglooLocks) =>
                                <VirtualSources iglooLocks={LoadedIglooLocks} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} deviceData={siteData[0]} setHasDeviceChanged={setHasDeviceChanged} DeviceDetails={siteData[1]} />
                            }
                        </Await>
                    </Suspense>
                )
            default:
                return null;
        }
    }

    const deviceUUID = sessionStorage.getItem("DeviceUUID");


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Function to check window size
    const checkWindowSize = () => {
        // Set your breakpoint (e.g., 768px)
        if (window.innerWidth < 1482) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    // Add event listener on mount
    useEffect(() => {
        checkWindowSize(); // Check size on mount
        window.addEventListener('resize', checkWindowSize); // Add event listener

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []); // Empty array ensures this runs only once on mount


    return (
        <div style={{ height: "100%", width: "100%", paddingBottom: "100px" }}>
            <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal}>
                {modalComponent}
            </Modal>
            <div className={classes.siteView}>
                <div className={classes.mainView}>
                    {/* <div className={classes.left}> */}
                        <Suspense fallback={
                            <div className={classes["loadingSpinner-container"]}>
                                <div style={{ width: "100%", height: "1290px", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px", gap:"15px" }}>
                                    <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                                </div>
                            </div>
                        }>
                            <Await resolve={siteData}>
                                {(loadedSiteData) =>
                                    <AppView singleDevicePins={loadedSiteData[0]} deviceUUID={deviceUUID} setHasDeviceChanged={setHasDeviceChanged} firmware={firmware}/>
                                }
                            </Await>
                        </Suspense>
                    {/* </> */}

                    {/* if the screen is too small then the table and controlsbar will not be rendered to the screen */}
                    {!isSmallScreen && <div className={classes.right}>
                        <ContorlsBar activeTable={activeTable} tableNameHandler={tableNameHandler} HandleRefreshTable={HandleRefreshTable} />
                        {activeTable && renderTable(activeTable)} 
                    </div>}
                </div>
            </div>


        </div>
    )
}

export default SiteView;

export async function loader(params) {
    // loader is a javascript function only, so gets data and header using session storage.
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const DeviceUUID = params.params.SiteID
    console.log(DeviceUUID);
    // Storing Device UUID to session storage for refresh purposes.
    sessionStorage.setItem('DeviceUUID', DeviceUUID);

    const todayDate = new Date();
    const todayDateString = todayDate.toISOString();

    const thirtyDaysAgoDate = new Date()
    thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30);
    const thirtyDaysAgoDateString = thirtyDaysAgoDate.toISOString();

    const apiData = {
        token,
        client
    }

    return defer({
        SiteData: await getSingleDeviceData(apiData, DeviceUUID),
        SiteDeviceUserList: getSingleDeviceUsers(apiData, DeviceUUID),
        SiteEventLogs: getDeviceEventLogsRanged(apiData, DeviceUUID, thirtyDaysAgoDateString, todayDateString),
    })
}

