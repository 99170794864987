import React, { Suspense, useEffect, useMemo, useState } from 'react'
import classes from "./Logs.module.css";
import TotalEvents from "../../UI/Cards/Logs Cards/TotalEvents/TotalEvents";
import Dropdown from '../../UI/Cards/Logs Cards/Dropdown/Dropdown';
import LinegraphPeaked from '../../UI/Graphs/linegraphPeaked';
import Export from '../../UI/Cards/Logs Cards/Export/Export';
import { Await, useLoaderData } from 'react-router-dom';
import useGetEventsMonth from '../../hooks/UseGetEventsMonth';
import LogsTableLayout from '../../Table/LogsTableLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceEventLogsRanged } from '../../../Api Methods/Api';
import UseGetDateEvents from '../../hooks/UseGetDateEvents';
import UseAddDateSuffix from '../../hooks/useAddDateSuffix';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import ComponentCard from '../../UI/Cards/ComponentCard/ComponentCard';
import CustomDropDown from "../../CustomDropDown/CustomDropDownWithIcon";
import DropdownSelect from '../../CustomDropDown/Input/DropdownSelect';
import SearchbarSmall from '../../UI/Inputs/SearchbarSmall';
import Button1 from '../../UI/Button/Button1';
import UseGetEventsForMonth from '../../hooks/UseGetEventsForMonth';
import DatePickerModal from './DatePickerModal';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { fillMissingDates, formatEvents, getLastSixMonths, FormateEventArrayWithGapObjects, AddGapObjects } from './LogsFunctions';
import DataManager from '../../../hooks/DataManager';
import ExportExcel from '../../ExcelExport/ExcelExport';
import { addDays, parse } from 'date-fns';

const Logs = ({ SiteEventLogs, apiData }) => {

  const [EventsData, setEventsData] = useState(SiteEventLogs.data[0].events);
  const [eventCount, setEventCount] = useState(SiteEventLogs.data[0].totalDevices['eventCount']);
  const dispatch = useDispatch();

  const [modalTitle, setModalTitle] = useState("Select a date range to view");
  const [modalComponent, setModalComponent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const todayDate = new Date();
  const todayDateString = todayDate.toISOString();
  const thirtyDaysAgoDate = new Date();
  thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30);
  const thirtyDaysAgoDateString = thirtyDaysAgoDate.toISOString();
  const [dateRange, setDateRange] = useState([thirtyDaysAgoDateString, todayDateString]);
  const [updateRange, setUpdateRange] = useState(0);
  const [dateClicked, setDateClicked] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [buttonText, setButtonText] = useState("Refresh");
  const dataMaganer = new DataManager();

  const singleDeviceHeaderData = useSelector(state => state.service.singleDeviceHeaderData);
  const key = singleDeviceHeaderData.key;

  function parseLocalDates(date, isStart){
    const day = date.split('/')[0];
    const month = date.split('/')[1];
    const year = date.split('/')[2].split(',')[0];

    let formattedDateString = "";

    if (isStart){
      formattedDateString = `${year}-${month}-${day}T00:00:00.0000Z`
    } else {
      formattedDateString = `${year}-${month}-${day}T23:59:29.0000Z`
    }
   
    return formattedDateString;
  }

  const normaliseDateRange = (value) => {

    const startDateUTC = value[0];
    const endDateUTC = value[1];
    const startDateLocal = startDateUTC.toLocaleString();
    const endDateLocal = endDateUTC.toLocaleString();
    
    let start = "";
    let end = "";

    if (!dateClicked) {
      start = parseLocalDates(startDateLocal, true);
      end = parseLocalDates(endDateLocal, false);
    } else {
      //already time converted
      start = value[0];
      end = value[1];
    }

    return [start, end];
  }

  const customShortcuts = [
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), new Date()]
    },
    {
      label: 'Last 7 Days',
      value: [addDays(new Date(), -7), new Date()]
    },
    {
      label: 'Last 30 Days',
      value: [addDays(new Date(), -30), new Date()]
    },
    {
      label: 'This Month',
      value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]
    }
  ];

  useEffect(() => {
    if (updateRange > 0){
      const HandleDeviceEventsList = async () => {
        const data = await getDeviceEventLogsRanged(apiData, DeviceUUID, dateRange[0], dateRange[1], dispatch);
        setEventsData(data.data[0].events);
        setEventCount(data?.data[0].totalDevices['eventCount']);
        setButtonText("Refresh");
        setIsLoading(false);
      }
      HandleDeviceEventsList();
    } else {
      setIsLoading(false);
    }
  }, [updateRange]);

  //To disable these dates in the range picker
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  const today = new Date();

  const disableDates = (date) => {
    return date < sixMonthsAgo || date > today;
  };

  const handleOk = (value) => {
    setDateRange(normaliseDateRange(value));
    setUpdateRange(updateRange + 1);
    setDateClicked(false);
    setIsLoading(true);
  };

  const handleShortcutClick = (shortcut, event) => {
    setDateRange(normaliseDateRange(shortcut.value));
    setUpdateRange(updateRange +1);
    setIsLoading(true);
  };

  const HandleCloseModal = () => {
    setShowModal(false);
  }

  const onSelect = () => {
    HandleCloseModal();
  };

  const DeviceUUID = sessionStorage.getItem('DeviceUUID');
  sessionStorage.setItem('DeviceUUID', DeviceUUID);
  const [isloading, setIsLoading] = useState(true);

  var m_names = new Array("Jan", "Feb", "Mar",
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec");

  function formatDate(date) {

    var sup = "";

    if (date.getDate() == 1 || date.getDate() == 21 || date.getDate() ==31)
    {
        sup = "st";
    }
    else if (date.getDate() == 2 || date.getDate() == 22)
    {
         sup = "nd";
    }
    else if (date.getDate() == 3 || date.getDate() == 23)
    {
        sup = "rd";
    }
    else
    {
        sup = "th";
    }

    var curr_month = date.getMonth();
    var curr_year = date.getFullYear();

    
    return (date.getDate() + sup + " " + m_names[curr_month] + " " + curr_year);
  }

  const handleDateClicked = (dateClicked) => {
    dataMaganer.setItem('preFilteredEvents', EventsData);
    dataMaganer.setItem('dateRange', dateRange);

    const start = dateClicked + "T00:00:00.0000Z"
    const end = dateClicked + "T23:59:00.000Z"
    setDateRange([start, end]);
    setUpdateRange(updateRange +1);
    setDateClicked(true);
    setIsLoading(true);
  }

  function filterEventsBySearch(value){

    if (searchValue === "" && value !== ""){
      //store
      dataMaganer.setItem('preFilteredEvents', EventsData);
    } else if (value === "" && searchValue !== ""){
      setEventsData(dataMaganer.getItem('preFilteredEvents'));
    }

    setSearchValue(value);
    const filteredEvents = dataMaganer.getItem('preFilteredEvents').filter(item => 
      item.user.toLowerCase().includes(value) || 
      item.eventDate.toLowerCase().includes(value) ||  
      item.user.toLowerCase().includes(value) ||
      item.type.toLowerCase().includes(value) ||
      item.subType.toLowerCase().includes(value) ||
      item.recipientName.toLowerCase().includes(value) || 
      item.eventData.toLowerCase().includes(value)
    );

    if (value !== ""){
      setEventsData(filteredEvents);
    }
  }

  function resetRange(){
    setDateRange(dataMaganer.getItem('dateRange'));
    setEventsData(dataMaganer.getItem('preFilteredEvents'));
    setDateClicked(false);
  }

  function clear(){
    setUpdateRange(updateRange +1);
    setSearchValue("");
  }

  function refresh(){
    setUpdateRange(updateRange +1);
    setButtonText("Refreshing...")
    setIsLoading(true);
  }

  return (
    <>
      <div>
        <DatePickerModal show={showModal} onClose={HandleCloseModal} title={modalTitle} onSelect={onSelect}>
          {modalComponent}
        </DatePickerModal>
      </div>

      <div>
        <ComponentCard className={classes["table-container"]}>
          <div className={classes["logs-container"]} >
            <div className={classes.top} >
              <TotalEvents totalEvents={eventCount} />
              <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>

              <ExportExcel
                excelData={EventsData}
                fileName={"Events For:" + key}
                title={"IPIO Portal-Events: " + key}
                colWidths={
                  [
                    { wch: 40 },
                    { wch: 40 },
                    { wch: 40 },
                    { wch: 40 },
                  ]
                }
              />

              <Button1 onClick={refresh}>{buttonText}</Button1>

              {dateClicked && <Button1 onClick={resetRange}>Back to range</Button1>}

              <div className="date-range-picker-dropdown">
                <DateRangePicker style={{border: "1.5px solid #c4c4c4", borderRadius:"10px", padding:"1px"}}
                  format="dd.MM.yyyy"
                  onOk={handleOk}
                  onShortcutClick={handleShortcutClick}
                  shouldDisableDate={disableDates}
                  calendarSnapping={true}
                  appearance='subtle'
                  cleanable={true}
                  ranges={customShortcuts}
                  value={[new Date(dateRange[0]), new Date(dateRange[1])]}
                />
              </div>

              <SearchbarSmall
                type="text"
                placeholder={"Search events"}
                value={searchValue}
                onChange={(e) => filterEventsBySearch(e.target.value)}
                
              />

              </div>
            </div>

            <div className={classes.lineGraphTableContainer}>
              {!dateClicked && <LinegraphPeaked events={EventsData} handleDateClicked={handleDateClicked} setIsLoading={setIsLoading} dateRange={dateRange} dateClicked={dateClicked}/>}
            </div>
          </div>

          < Suspense fallback={
            < div className={classes["loadingSpinner-container"]} >
              <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
            </div >
          }>
            <Await resolve={EventsData}>
              {(loadedTableData) =>
                <LogsTableLayout data={EventsData} isloading={isloading} Filtering={""} />
              }
            </Await>
          </Suspense >
        </ComponentCard>
      </div>
    </>
  )
}

export default Logs;
