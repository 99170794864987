import React, { useEffect, useState } from "react";
import classes from './Card.module.css';

const Card = (props, { key }) => {
    const delayFactor = 0.05; 
    const delay = props.index * delayFactor;

    return (
        <div key={key || null} style={{ width: props.cardWidth, height: props.cardHeight, animationDelay: `${delay}s` }} className={`${classes.card} ${props.className} `} onClick={props.onClick}>
            <div style={{ display: "flex" }}> 
                <div style={{ height: "12px", width: "100%", backgroundColor: "#168aad", borderRadius: "12px 12px 0px 0px"}} />
            </div>
            {props.children}
        </div>
    );
}

export default Card;

/* 

------SETS THE BAR COLOUR ACCORDING TO COMPANY COLOUR
    console.log(company);

    const companyClass = company
        ? classes[company] || classes.defaultCompany // Use the company class or fallback to default
        : classes.defaultCompany;

        return (
            <div key={key || null} style={{ width: props.cardWidth, height: props.cardHeight, animationDelay: `${delay}s` }} className={`${classes.card} ${props.className}`} onClick={props.onClick}>
                <div style={{ display: "flex" }}> 
                    <div className={companyClass} style={{ height: "15px", width: "100%", borderRadius: "12px 12px 0px 0px" }} />
                </div>
                {props.children}
            </div>
        );


*/