import React, { useMemo, useEffect, Suspense, useState } from 'react'
import classes from './GroupsGrid.module.css'
import GroupCard from './GroupCard';
import Card from './Card';
import DataManager from '../../hooks/DataManager';

const GroupsGrid = ({GroupsList, setUpdate, update}) => {

    const dataManager = new DataManager();

    const handleGroupClicked = (groupName, groupID) => {
        console.log("clicked", groupName);
        dataManager.setItem('groupClicked', groupName)   
        dataManager.setItem('groupClickedID', groupID)   

    }

    return (
        <div className={classes["grid-container"]}>
            <ul className={classes["grid-ul"]} style={{ display: GroupsList.length < 5 && "flex", gap: GroupsList.length < 5 && "54px" }}>
                {GroupsList.map((group) => (
                    <li className={classes["grid-li"]}>
                        <Card className={classes.card} company={group.name}>
                            <GroupCard cardData={group} handleGroupClick={handleGroupClicked} groups={GroupsList} setUpdate={setUpdate} update={update}></GroupCard>                        
                        </Card>
                    </li>
                ))}
            </ul>       
        </div>
    )
}

export default GroupsGrid