import React, { Children, useEffect, useRef, useState } from 'react'
import classes from "./GroupUserCardContentLayout.module.css";
import ActiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";
import installerActive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer-on.png";
import installerInactive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer off.png";
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"
import arrowIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from '../../store/auth-Slice';
import { useDispatch } from 'react-redux';
import DropdownList from '../../components/UI/Cards/DropdownList';
import useClickOutside from '../../components/hooks/useClickOutside';

const GroupUserCardContentLayout = ({ cardData, apiData }) => {
console.log(cardData);
    const [userData, setUserData] = useState(cardData);
    useEffect(() => {
        setUserData(cardData);
    }, [cardData])

    // const { created, email, fullName, id, lastLoggedIn, notifications, pin, portalAccess, portalPassword, portalRole, salt, timezone, token, twoFactor, verified, verifiedOn } = userData;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //set user values here from cardData
    const id = cardData.id;
    const groupID = cardData.groupID;
    const userID = cardData.userID;
    const portalRole =  cardData.portalRole;
    const accountToDrawNamesFrom = cardData.accountToDrawNamesFrom;
    const isAdmin = cardData.groupOwner;
    const name = "";
    const email = "";

    const HandleNavigateToUsersDevices = (UserID, fullName) => {
        sessionStorage.setItem('ClickedUserID', UserID);
        sessionStorage.setItem('ClickedUserFullName', fullName);

        dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

        navigate("/Dashboard/Users/UserSites");
    }

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
        setOpenDropdownRowId(null);
    });




    return (
        <div className={classes.GroupCardContentLayout}>

            <div className={classes["top-wrapper"]} >
                <div className={classes["siteDetails-layout"]} onClick={() => HandleNavigateToUsersDevices(userID, name)}>
                    <h3>{"Username..."}</h3>
                    <h3 style={{ fontWeight: "400" }} className={classes.tagName}>{"Email Address..."}</h3>
                    <br></br>
                    <h3 style={{ fontWeight: "400" }} className={classes.tagName}>{isAdmin == 1 ? "Administrator" : "User"}</h3>
                </div>
                <div className={classes["icons-container"]}>
                    
                </div>
            </div>
        </div>
    )
}

export default GroupUserCardContentLayout;

